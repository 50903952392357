import React from 'react';
import PropTypes from 'prop-types';
import FluidImage from '@utility/FluidImage';
import VideoPlayer from '@utility/VideoPlayer';
import cn from 'classnames';
import * as styles from './styles';

const HeroSecondary = ({ data: { image, video, description } }) => {
  return (
    <div className="mb-6 lg:mb-20">
      {video && video.url ? (
        <div>
          <VideoPlayer video={video} />
        </div>
      ) : (
        <FluidImage
          src={image}
          maxWidth={2880}
          maxHeight={1920}
          mobileRatio={1.2}
          className={styles.heroSecondaryImage}
        />
      )}
    </div>
  );
};

HeroSecondary.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.shape({
      asset: PropTypes.shape({
        _id: PropTypes.string,
      }),
    }),
    video: PropTypes.object,
    description: PropTypes.string,
  }).isRequired,
};

export default HeroSecondary;
