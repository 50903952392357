// extracted by mini-css-extract-plugin
export var hero = "styles-module--hero--d8129";
export var heroDescription = "styles-module--heroDescription--80868";
export var heroImage = "styles-module--heroImage--e51c3";
export var heroImageHalf = "styles-module--heroImageHalf--0fda7";
export var heroTitle = "styles-module--heroTitle--931b0";
export var heroTitleContainer = "styles-module--heroTitleContainer--506d7";
export var heroTransform = "styles-module--heroTransform--d0372";
export var heroTransformLeft = "styles-module--heroTransformLeft--86c54";
export var heroTransformRight = "styles-module--heroTransformRight--b8161";
export var heroTransformTop = "styles-module--heroTransformTop--336c2";